<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    >
    </loading>

    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{ error_message }}</CAlert>
    </div>

    <!-- Title -->
    <b-row>
      <b-col>
        <h3>KPI Consolidado financiero por año</h3>
      </b-col>
    </b-row>

    <!-- Filter -->
    <section>
      <b-row>
        <b-col lg="4">
          <b-form-group>
            <small>Fecha</small>
            <v-select
              v-model="yearSelected"
              :options="yearsRange"
              placeholder="Selecciona periodo"
              :class="{
                'form-control is-invalid': $v.citySelected.$error,
                'form-control is-valid': !$v.citySelected.$invalid
              }"
            ></v-select>
            <b-form-invalid-feedback v-if="!$v.citySelected.required"
              >Debes seleccionar una ciudad</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col lg="4">
          <b-form-group>
            <small>Ciudades</small>
            <v-select
              v-model="citySelected"
              :options="cities"
              placeholder="Selecciona tu ciudad"
              :class="{
                'form-control is-invalid': $v.citySelected.$error,
                'form-control is-valid': !$v.citySelected.$invalid
              }"
            ></v-select>
            <b-form-invalid-feedback v-if="!$v.citySelected.required"
              >Debes seleccionar una ciudad</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col lg="2">
          <br />
          <b-button
            @click="submit"
            variant="warning"
            :disabled="this.$v.$invalid"
          >
            Consultar
          </b-button>
        </b-col>
      </b-row>
    </section>

    <section>
      <b-card bg-variant="white">
        <b-row>
          <b-col lg="12" md="12">
            <h4>INGRESOS</h4>
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
              :selectable="true"
            >
           
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </section>
<!-- Bills -->
    <section>
      <b-card bg-variant="white">
        <b-row>
          <b-col lg="12" md="12">
            <h4>EGRESOS</h4>
            <b-table
              :items="billsItems"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
              :selectable="true"
            >
           
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </section>
<!-- Utility -->
    <section>
      <b-card bg-variant="white">
        <b-row>
          <b-col lg="12" md="12">
            <h4>UTILIDAD</h4>
            <b-table
              :items="utilityItems"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
              :selectable="true"
            >
           
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
moment.locale("es");
const OWNER_ID = 1;
const ADMIN_ID = 2;
export default {
  name: "ConsolidatedReport",
  components: { Loading },
  data() {
    return {
      loading: true,
      errors: [],
      labels: {
        "es-ES": {
          labelPrevYear: "Año anterior",
          labelPrevMonth: "Mes anterior",
          labelCurrentMonth: "Mes actual",
          labelNextMonth: "Siguiente mes",
          labelNextYear: "Siguiente año",
          labelTodayButton: "Hoy",
          labelResetButton: "Limpiar",
          labelHelp: "Usa el cursor para navegar en las fechas"
        }
      },
      cities: [{ value: 0, label: "Todas" }],
      citySelected: { value: 0, label: "Todas" },
      minDate: "2023ˇ-01-01T00:00:00.000Z",
      yearsRange: [],
      yearSelected: { value: 2023, label: 2023 },
      baseYear: 2023,
      items: [],
      billsItems: [],
      utilityItems: [],
      fields: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterText: null,
      filterOn: []
    };
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    let access = this.userProfile.acceso.id;
    this.setYearsRange();
    console.log(this.yearsRange);
    this.date = moment(new Date()).format("YYYY-MM-") + "01";
    this.date = moment(new Date()).format("YYYY-MM-") + "01";

    const currentYear = moment(new Date()).format("YYYY")
    
    this.yearSelected = {
      value: currentYear,
      label: currentYear
    };


    if (access == OWNER_ID || access == ADMIN_ID) {
      this.fetchCities();
    } else {
      this.flagErr = true;
      this.errMsg = "No posees los permisos para este módulo";
      this.loading = false;
    }
  },
  validations: {
    citySelected: {
      required
    },
    yearSelected: {
      required
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchCities() {
      this.loading = true;
      let getCities = this.$firebase.functions().httpsCallable("getCities");
      getCities()
        .then(response => {
          if (response.data != null) {
            let cities = response.data.data;
            if (this.userProfile.acceso.id == OWNER_ID) {
              cities.map(city => {
                this.cities.push({
                  value: city.id,
                  label: city.nombre
                });
              });
            } else if (this.userProfile.acceso.id == ADMIN_ID) {
              let cityFound = cities.find(
                city => city.nombre == this.userProfile.ciudad
              );
              this.citySelected = {
                value: cityFound.id,
                label: cityFound.nombre
              };
              this.cities = [
                {
                  value: cityFound.id,
                  label: cityFound.nombre
                }
              ];
            }
          }
        })
        .catch(error => console.log(error))
        .finally(_ => {
          this.loading = false;
          this.submit();
        });
    },
    submit() {
      this.loading = true;
     
      let payload = {
        year: Number(this.yearSelected.value),
        city: this.citySelected
      };

      let fetchConsolidates = this.$firebase.functions().httpsCallable('anualConsolidateKPI')

      fetchConsolidates(payload).then((result) => {
        console.log(result.data)
        const data = result.data
        this.fields = data.fields
        this.items = data.items
        this.billsItems = data.billsItems
        this.utilityItems = data.utilityItems
      }).catch(error => {
        console.error(error)
      }).finally(() => this.loading = false)
    },
    setYearsRange() {
      const currentYear = moment(new Date()).format("YYYY");
      while (this.baseYear <= currentYear) {
        const item = {
          value: this.baseYear,
          label: this.baseYear
        };
        this.yearsRange.push(item);
        this.baseYear++;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
